/*.card + .card {*/
/*    margin-top: 10px;*/
/*}*/

.container {
    display: grid;
    grid-row-gap: 10px;
    background-color: #ddd;
    max-width:100%;
}

.nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    text-align: center;
    margin: 10px;
}
.navBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    text-align: center;
    align-items: baseline;
}
.marginTop {
    /*margin-top: 50px;*/
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
}

.progress {
    width:100%;
}

.sorting {
    opacity: 0.4
}

.bouncy-ball {
    /*background: #68b9ff;*/
    border-radius: 50%;
    position: fixed;
    top: 6.8vh;
    left: 0;
    /*width: 80px;*/
    /*height: 80px;*/
    -ms-animation: bounce 3s infinite;
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
    text-align: center;
}

@-ms-keyframes bounce {
    0% {
        left: 0;
    }
    50% {
        left: calc(100% - 20px)
    }
    100% {
        left: 0;
    }
}

@-moz-keyframes bounce {
    0% {
        left: 0;
    }
    50% {
        left: calc(100% - 20px)
    }
    100% {
        left: 0;
    }
}

@-webkit-keyframes bounce {
    0% {
        left: 0;
    }
    50% {
        left: calc(100% - 20px)
    }
    100% {
        left: 0;
    }
}

@keyframes bounce {
    0% {
        left: 0;
    }
    50% {
        left: calc(100% - 20px)
    }
    100% {
        left: 0;
    }
}
